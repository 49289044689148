module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-starberry-lomondgroup/gatsby-browser.js'),
      options: {"plugins":[],"news_insight":"about-hardisty/insights","branch_finder":"contact/branch-finder","area_cover":"about-hardisty/areas-we-cover","teams":"contact/meet-the-team"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"337c4c5a08793fc7e935befc51ccb646"},
    }]
